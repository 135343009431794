@charset "UTF-8";

/*-----------------------------------------------
home
-----------------------------------------------*/
main.home {

    .home-top {
        .home-concept {
            padding: 5% 0;

            @include mb {
                padding: 10% 0 12.5%;
            }

            .home-concept__ttl {
                margin-bottom: 40px;

                @include mb {
                    margin-bottom: 7.5%;
                }
            }

            // vision
            .home-concept__vision {
                margin-bottom: 10%;

                .home-concept__vision-ttl {
                    padding: 0 10%;
                }

                .home-concept__vision-cont {
                    margin-top: -10%;
                    padding: 0 10%;

                    @include mb {
                        padding: 0 5%;
                    }

                    &--inner {
                        padding: 22.5% 10% 15%;

                        @include mb {
                            padding: 22.5% 8% 15%;
                        }
                    }

                    .home-concept__vision-txt {
                        & + .home-concept__vision-txt {
                            margin-top: 1em;
                        }
                    }
                }
            }

            // values
            .home-concept__values {
                padding: 0 10% 20%;

                @include mb {
                    padding: 0 5% 15%;
                }

                .home-concept__values-ttl {
                    width: 60%;
                    margin: 0 0 40px auto;
                }

                .home-concept__values-cont {
                    .values-items {
                        display: flex;
                        flex-wrap: wrap;
                        margin-bottom: 200px;
                        margin: 0 -1%;

                        @include mb {
                            margin: 0 -1.5%;
                        }

                        .values-item {
                            width: 33.33333333333%;
                            // IE calc /3 だけcolumn落ちするバグ
                            // width: calc(100% / 3);
                            padding: 0 1%;

                            @include mb {
                                width: 50%;
                                padding: 0 1.5%;
                            }

                            .values-item--inner  {
                                width: 100%;
                                height: 0;
                                padding-top: 102.0942408377%;
                                padding-top: calc(390 / 382 * 100%);
                                background: url('../images/img-values_img.png') no-repeat center center / contain;
                                position: relative;
                            }

                            .values-item__box {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                width: 100%;
                                height: 100%;
                                transform: translate(-50%, -50%);
                                padding: 15%;

                                @include onecol {
                                    height: auto;
                                }

                                .values-item__box-ttl {
                                    text-align: center;
                                    font-size: calc(50vw / 35);

                                    @include onecol {
                                        font-size: calc(100vw / 42);
                                    }
                                    @include max414 {
                                        font-size: calc(100vw / 22);
                                    }
                                }

                                .values-item__box-txt {
                                    font-size: $sizeS;
                                    line-height: 1.6;

                                    @include min1536 {
                                        line-height: 1.8;
                                    }
                                }
                            }

                            // 背景画像個別設定
                            &:nth-of-type(2),
                            &:nth-of-type(7) {
                                .values-item--inner {
                                    color: $base_color;
                                    text-shadow: 0 1px 1px rgba(#709808, 0.3);
                                    background-image: url('../images/img-values_ygn.png');
                                }
                            }
                            &:nth-of-type(3),
                            &:nth-of-type(4) {
                                .values-item--inner {
                                    color: $base_color;
                                    background-image: url('../images/img-values_nvy.png');
                                }
                            }
                            &:nth-of-type(8) {
                                .values-item--inner {
                                    background-image: url('../images/img-values_wdc.png');
                                }
                            }

                            // pc / tab ----------------
                            // 真ん中2column
                            &:nth-of-type(4),
                            &:nth-of-type(5) {
                                @include tp {
                                    width: 50%;
                                }

                                .values-item--inner {
                                    @include tp {
                                        width: 66.66666666667%;
                                        width: calc(100% / 3 * 2);
                                        padding-top: 68.06282722513%;
                                        padding-top: calc(390 / 382 * (100% / 3 * 2));
                                    }
                                }
                            }
                            &:nth-of-type(4) {
                                @include tp {
                                    display: flex;
                                    justify-content: flex-end;
                                }
                            }
                            &:nth-of-type(5) {
                                @include tp {
                                    display: flex;
                                    justify-content: flex-start;
                                }
                            }

                            // 位置調整（4つめ〜）
                            &:nth-child(n + 4) {
                                @include tp {
                                    margin-top: -4.75%;
                                }
                            }

                            // sp ----------------------
                            // 真ん中1column
                            &:nth-of-type(3),
                            &:nth-of-type(6) {
                                @include mb {
                                    width: 100%;
                                    display: flex;
                                    justify-content: center;
                                }

                                .values-item--inner {
                                    @include mb {
                                        width: 50%;
                                        width: calc(100% / 2);
                                        padding-top: 51.04712041885%;
                                        padding-top: calc(390 / 382 * 50%);
                                    }
                                }
                            }

                            // 位置調整（3つめ〜）
                            &:nth-child(n + 3) {
                                @include mb {
                                    margin-top: -7.5%;
                                }
                            }
                        }
                    }
                }
            }
        }

        .home-service {
            .home-service__deco {
                margin-top: -15%;
            }

            .home-service--inner {
                margin-top: -8%;
                padding-bottom: 17.5%;
                background: $color_2;

                .home-service__ttl {
                    margin-bottom: 30px;

                    @include mb {
                        margin-bottom: 5%;
                    }
                }

                .home-service__txt {
                    margin-bottom: 10%;
                    padding: 0 10%;
                }

                .home-service__cards {
                    padding: 0 10%;

                    @include mb {
                        padding: 0 5%;
                    }

                    .home-service__card {
                        padding: 10%;
                        position: relative;
                        background: $base_color;
                        border-radius: 4px;

                        & + .home-service__card {
                            margin-top: 12.5%;
                        }

                        .home-service__card-ttl {
                            margin-bottom: 5%;
                            padding-left: 24%;
                            position: relative;
                            // font-size: $sizeM;
                            color: $ttl_color;

                            font-size: calc(50vw / 30);

                            @include onecol {
                                font-size: calc(100vw / 32);
                            }

                            @include mb {
                                font-size: calc(100vw / 21);
                            }

                            .home-service__card-icon {
                                max-width: 24%;
                                position: absolute;
                                bottom: 0;
                                left: -5%;
                            }
                        }

                        .home-service__card-num {
                            max-width: 10%;
                            position: absolute;
                            right: -5%;
                            top: -20%;

                            @include mb {
                                top: -10%;
                            }
                        }
                    }
                }
            }
        }
    }

    .home-works {
        width: 100%;
        height: 100vh;
        display: flex;
        position: relative;

        @include onecol {
            display: block;
            height: auto;
        }

        .home-works__view {
            width: 50%;
            height: 100%;

            @include onecol {
                width: 100%;
            }

            .home-works__view--cont {
                width: 100%;
                height: 100%;
            }

            .home-works__view-bg {
                width: 100%;
                height: 100%;
                background: no-repeat center / cover;
                position: relative;

                @include onecol {
                    width: 100%;
                    padding: 15% 0;
                }

                .home-works__view-tab {
                    max-width: 50%;
                    width: calc(100vw / 1);
                    position: absolute;
                    top: 50%;
                    right: 10%;
                    transform: translateY(-50%);

                    @include onecol {
                        max-width: 60%;
                        position: static;
                        margin: 0 auto;
                        transform: none;
                    }

                    .view-tab {
                        width: 100%;
                        height: 100%;
                        position: relative;
                        overflow: hidden;
                        border-radius: 5.5%;
                        transition: all ease-in-out .3s;
                        box-shadow: 4px 4px 26px 0px rgba(#232d49, 0.6);

                        .view-tab__screen {
                            width: 100%;
                            height: 100%;
                            border-radius: 5%;
                            transition: transform 1s ease-in;
                        }

                        .view-tab__frame {
                            position: absolute;
                            top: 0;
                            left: 0;
                        }

                        &:hover {
                            transform: translateY(-2px);
                            box-shadow: 10px 10px 56px 0px rgba(#232d49, 0.8);

                            .view-tab__screen {
                                width: 100%;
                                height: 100%;
                                transform: scale(1.02);
                            }
                        }
                    }
                }
            }
        }

        .home-works__intr {
            width: 50%;
            height: 100%;
            color: $base_color;
            position: relative;
            background: $color_1;

            @include onecol {
                width: 100%;
                height: auto;
                padding: 10% 0 20%;
            }

            &--cont {
                width: 100%;
                height: 100%;

                .scrollarea {
                    height: 100%;
                    padding-top: 5%;
                    overflow-y: scroll;
                    -webkit-overflow-scrolling: touch;
                    overflow-scrolling: touch;
                    -ms-overflow-style: none;

                    &::-webkit-scrollbar {
                        display: none;
                    }

                    @include onecol {
                        padding-top: 0;
                        overflow-y: inherit;
                    }
                }
            }

            .home-works__intr-ttl {
                margin-bottom: 30px;

                @include mb {
                    margin-bottom: 5%;
                }

                .ttl-main {
                    color: $base_color;
                }
            }

            .home-works__intr-home {
                &.intr-home {
                    padding: 0 10%;

                    .intr-home__txt {
                        span {
                            display: inline-block;
                            margin-top: .5em;
                            font-size: $sizeS;
                            letter-spacing: 0.06em;

                            a {
                                color: $key_color;
                            }
                        }
                    }
                }
            }

            .home-works__intr-single {
                padding: 0 10%;

                .intr-single__ttl {
                    margin-bottom: .5em;

                    font-size: 2.4rem;
                    font-size: calc(50vw / 30);

                    @include onecol {
                        font-size: calc(100vw / 38);
                    }

                    @include mb {
                        font-size: calc(100vw / 20);
                    }
                }

                .intr-single__link {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    @include onecol {
                        margin-bottom: 10%;
                    }

                    .intr-single__link-txt {
                        padding-right: 10px;
                        color: $color_3;
                    }

                    .intr-single__link-img {
                        display: block;

                        img {
                            max-width: 44px;
                            height: 44px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            animation: radial-pulse 1s infinite;
                            border-radius: 50%;

                            @keyframes radial-pulse {
                                0% {
                                    box-shadow: 0 0 0 0px rgba(#adeb0e, 0.5);
                                }

                                100% {
                                    box-shadow: 0 0 0 12px rgba(0, 0, 0, 0);
                                }
                            }

                            @include onecol {
                                max-width: 30px;
                                height: 30px;
                            }
                        }
                    }
                }

                .intr-single__def {
                    margin-bottom: 10%;

                    .intr-single__def-ttl {
                        color: $key_color;
                        margin-bottom: .5em;
                    }

                    .intr-single__def-data {
                        margin-bottom: 2em;

                        &:last-of-type {
                            margin-bottom: 0;
                        }
                    }
                }

                .intr-single__tags {
                    display: flex;
                    flex-wrap: wrap;
                    margin: -2.5px;

                    .intr-single__tag {
                        margin: 2.5px;
                        padding: .5em 1em;
                        font-size: 1.2rem;
                        border-radius: 3px;
                        background: $color_4;
                        // safari 改行バグが入る為
                        font-feature-settings: "pkna";
                    }
                }
            }

            .home-works__intr-slider {
                margin-top: 30px;
                padding: 0 10%;

                .intr-box__items {
                    margin: -2.5px;

                    .intr-box__item {
                        display: flex;
                        flex-wrap: wrap;

                        &--inner {
                            width: calc(100% / 3);
                            padding: 2.5px;

                            a {
                                display: block;
                                opacity: 1;
                            }
                        }

                        .intr-box__item-fig {
                            &.item-fig {
                                position: relative;
                                width: 100%;
                                color: #fff;
                                background-color: #000000;
                                overflow: hidden;
                                border-radius: 6px;

                                * {
                                    box-sizing: border-box !important;
                                    transition: all 0.4s ease-in;
                                }

                                .item-fig__cap {
                                    position: absolute;
                                    width: 100%;
                                    height: 100%;
                                    top: 0;
                                    right: 0;
                                    padding: 5%;
                                    z-index: 1;
                                    opacity: 0;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;

                                    .item-fig__cap-ttl {
                                        line-height: 1.5em;
                                        font-size: 1.2rem;
                                        font-weight: bold;
                                        color: $base_color;
                                        background: -webkit-linear-gradient(0deg, #cbff45, #ff57ce);
                                        -webkit-background-clip: text;
                                        -webkit-text-fill-color: transparent;
                                    }
                                }
                                // 上下ボーダー
                                &:before,
                                &:after {
                                    position: absolute;
                                    width: 0%;
                                    height: 1px;
                                    content: '';
                                    background-color: #fff;
                                    z-index: 1;
                                    transition: all .8s ease-in;
                                    opacity: 0.8;
                                }

                                &:before {
                                    top: 0;
                                    left: 0;
                                    background-color: #ff57ce;
                                }

                                &:after {
                                    bottom: 0;
                                    right: 0;
                                    background-color: #cbff45;
                                }

                                &:hover,
                                &.hover {
                                    .item-fig__img {
                                        opacity: 0.3;
                                    }

                                    .item-fig__cap {
                                        opacity: 1;

                                        &:before,
                                        &:after {
                                            height: 100%;
                                            opacity: 0.1;
                                        }
                                    }
                                    // 上下ボーダー
                                    &:before,
                                    &:after {
                                        width: 100%;
                                        opacity: 0.1;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            // 親コンテナに余白をいれても、IEバグで余白が確保が消える為
            .home-works__intr-space {
                width: 100%;
                height: 15%;

                @include onecol {
                    display: none;
                }
            }

            .home-works__intr-mask {
                width: 100%;
                position: absolute;
                bottom: 0;
                opacity: 1;
                visibility: visible;
                transition: all 0.5s;

                @include onecol {
                    display: none;
                }
            }

            &:hover {
                .home-works__intr-mask {
                    opacity: 0;
                    visibility: hidden;
                }
            }
        }
    }

    .home-btm {
        .split__screen--left {
            @include onecol {
                display: none;
            }
        }

        .home-company {
            padding: 5% 0 25%;

            @include mt {
                padding: 10% 0 30%;
            }

            .home-company__ttl {
                margin-bottom: 40px;

                @include mb {
                    margin-bottom: 7.5%;
                }
            }

            // prof
            .home-company__prof {
                .home-company__prof-ttl {
                    padding: 0 10%;
                }

                .home-company__prof-cont {
                    margin-top: -10%;
                    padding: 0 10%;

                    @include mb {
                        padding: 0 5%;
                    }

                    &--inner {
                        padding: 22.5% 10% 12.5%;

                        @include mb {
                            padding: 22.5% 8% 12.5%;
                        }
                    }

                    .home-company__prof-def {
                        &.prof-def {
                            padding: 0 5%;
                            overflow: hidden;

                            @include mb {
                                padding: 0;
                            }

                            .prof-def__ttl {
                                float: left;
                                clear: both;
                            }

                            .prof-def__data {
                                padding-left: 140px;
                                margin-bottom: 1.25em;

                                @include mt {
                                    padding-left: 110px;
                                }

                                a {
                                    color: $color_5;
                                }

                                &:last-of-type {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }

            // map
            .home-company__map {
                margin-top: -2px;

                .home-company__map-area {
                    height: 420px;
                }

                .home-company__map-cont {
                    margin: 0 10%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    .home-company__map-walk {
                        margin-bottom: 20px;
                        font-size: $sizeS;
                    }

                    .home-company__map-btn {
                        width: 100%;
                        max-width: 180px;

                        @include mb {
                            max-width: 100%;
                        }
                    }
                }
            }
        }

        .home-contact {
            .home-contact__deco {
                margin-top: -15%;
            }

            .home-contact--inner {
                margin-top: -7%;
                padding-bottom: 15%;
                background: $color_2;

                .home-contact__ttl {
                    margin-bottom: 30px;

                    @include mb {
                        margin-bottom: 5%;
                    }
                }

                .home-contact__txt {
                    margin-bottom: 40px;
                    padding: 0 10%;

                    @include mb {
                        margin-bottom: 10%;

                        br {
                            display: none;
                        }
                    }
                }

                .home-contact__form {
                    padding: 0 10%;
                }
            }
        }
    }
}
