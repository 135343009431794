@charset "UTF-8";

/*-----------------------------------------------
all
-----------------------------------------------*/
html {
    height: 100%;
    min-height: 100%;
    font-size: $fontsizeBase;
}

body {
    width: 100%;
    height: 100%;
    min-height: 100%;
    padding: 0 !important;
    @include fontFamily-gglFontsJP();
    @include fontBeauty();
    @include fontsize();
    line-height: 1.8;
    text-align: justify;
    text-justify: inter-ideograph;
    word-break: break-all;
    color: $font_color;
    background: #fff;

    @include selection {
        color: $base_color;
        background: $color_3;
    }
}

a {
    text-decoration: none;
    color: inherit;
}

a,
button {
    &:hover {
        cursor: pointer;
        opacity: .8;
    }
}

img {
    width: 100%;
    max-width: 100%;
    height: auto;
    border: 0;
}

figure {
    line-height: 0;
}

// Windows (全バージョン)のみ
.win {
    @include scrollbar();
}

/*-----------------------------------------------
 generic
-----------------------------------------------*/
/*---- block  ----*/
.dsn {
    display: none !important;
}

/*---- border  ----*/
.bdn {
    border: none !important;
}

/*--- clearfix ---*/
.clear {
    clear: both;
}

.cf {
    display: block;

    &::after {
        clear: both;
        visibility: hidden;
        display: block;
        height: 0;
        content: "";
    }
}

/*---- display  ----*/
.mb-on {
    @include sm {
        display: none;
    }
}

.mb-off {
    @include onecol {
        display: none;
    }
}

/*-----------------------------------------------
 common
-----------------------------------------------*/
/*---- header  ----*/
.header {

    .header--pc {
        width: 150px;
        height: 100vh;
        background: $key_color;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;

        @include onecol {
            display: none;
        }

        .header-ttl {
            height: 40%;
            padding: 20%;

            a {
                display: block;
            }
        }

        .header-gnav {
            height: 60%;

            .header-gnav__lists {
                .header-gnav__list {
                    a {
                        display: block;
                        margin: 0 0 20px 10px;
                        padding-bottom: 4px;
                        position: relative;
                        transition: 1s ease;

                        @include fontFamily-gglFontsENG();
                        line-height: normal;
                        text-transform: uppercase;

                        &:after {
                            content: '';
                            display: block;
                            position: absolute;
                            width: 0;
                            height: 1px;
                            bottom: 0;
                            left: 0;
                            background: linear-gradient(to right, #d79872, #41577c);
                            transition: .5s all;
                        }

                        &:hover {
                            margin-left: 20px;

                            &::after {
                                width: 100%;
                            }
                        }
                    }

                    &:last-of-type {
                        a {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }

    .header--mb {
        display: none;

        @include onecol {
            display: block;
        }

        .header-gnav {
            display: flex;
            align-items: center;
            justify-content: center;

            .header-gnav--inner {
                width: 100%;
                height: 80%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .header-gnav__lists {
                width: 100%;

                .header-gnav__list {
                    width: 100%;

                    a {
                        width: 100%;
                        display: block;
                        text-align: center;
                        padding: 10px 0;
                    }
                }
            }
        }
    }
}


/*---- footer  ----*/
.footer {
    padding: 2.5% 10%;
    background: $base_color;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap;

    @include mb {
        display: block;
    }

    .footer__left {
        .footer__left-privacy {
            display: inline-block;
            font-size: 1.0rem;
        }

        .footer__left-copylight {
            line-height: normal;

            small {
                font-size: 1.0rem;
            }
        }
    }

    .footer__right {
        .footer__right-logo{
            max-width: 120px;
            margin-bottom: 2px;

            @include mb {
                max-width: 80px;
                margin-left: auto;
                text-align: right;
            }
        }
    }
}
