@charset "UTF-8";

/*--- icon ---*/

.icon {
    position: relative;
    padding-left: 23px;
    padding-left: calc( 16px + .5em );

    @include min1366 {
        padding-left: calc( 18px + .5em );
    }
    @include min1536 {
        padding-left: calc( 19px + .5em );
    }
    @include min1920 {
        padding-left: calc( 21px + .5em );
    }

    &:before {
        display: inline-block;
        content: '';
        width: 16px;
        height: 16px;
        position: absolute;
        top: 0;
        left: 0;
        background: no-repeat center center / contain;

        @include min1366 {
            width: 18px;
            height: 18px;
        }
        @include min1536 {
            width: 19px;
            height: 19px;
        }
        @include min1920 {
            width: 21px;
            height: 21px;
        }
    }

    &--pin {
        &:before {
            top: 50%;
            transform: translateY(-50%);
            background-image: url('../images/icon_pin.png');
        }
    }
}
