@charset "UTF-8";

/*-----------------------------------------------
mixins
-----------------------------------------------*/
// @media
@mixin mb_320 {
    @media screen and (max-width : 320px) and (orientation: portrait) {
        @content;
    }
}

@mixin mb {
    @media screen and (max-width : 414px) and (orientation: portrait) {
        @content;
    }
}

@mixin mt {
    @media screen and (max-width : 768px) {
        @content;
    }
}

@mixin xmt {
    @media screen and (max-width : 1024px) {
        @content;
    }
}

@mixin tab {
    @media screen and (min-width : 415px) and (max-width : 768px) {
        @content;
    }
}

@mixin tp {
    @media screen and (min-width : 415px) {
        @content;
    }
}

@mixin sm {
    @media screen and (min-width : 769px) {
        @content;
    }
}

@mixin md {
    @media screen and (min-width : 1024px) {
        @content;
    }
}

@mixin lg { // mac
    @media screen and (min-width : 1280px) {
        @content;
    }
}

@mixin xlg {
    @media screen and (min-width : 1980px) {
        @content;
    }
}


// fontsize ----------------------------------

// max「まで」
@mixin onecol { // 1column
    @media screen and (max-width : 999px) {
        @content;
    }
}
@mixin max768 { // ipad
    @media screen and (max-width : 768px) {
        @content;
    }
}
@mixin max414 { // iPhone
    @media screen and (max-width : 414px) and (orientation: portrait) {
        @content;
    }
}
@mixin max320 { // iPhone - s
    @media screen and (max-width : 320px) and (orientation: portrait) {
        @content;
    }
}

// min「から」
@mixin min1280 { // 6位 mac
    @media screen and (min-width : 1280px) {
        @content;
    }
}
@mixin min1366 { // 2位
    @media screen and (min-width : 1366px) {
        @content;
    }
}
@mixin min1536 { // 5位
    @media screen and (min-width : 1536px) {
        @content;
    }
}
@mixin min1920 { // 1位
    @media screen and (min-width : 1920px) {
        @content;
    }
}

@mixin fontsize() {
    font-size: 1.4rem;

    @include onecol {
        font-size: 1.4rem;
    }
    @include max768 {
        font-size: 1.4rem;
    }
    @include max414 {
        font-size: 1.4rem;
    }
    @include max320 {
        font-size: 1.3rem;
    }

    // min
    @include min1280 {
        font-size: 1.4rem;
    }
    @include min1366 {
        font-size: 1.5rem;
    }
    @include min1536 {
        font-size: 1.6rem;
    }
    @include min1920 {
        font-size: 1.8rem;
    }
}


// font ---------------------------------------
@mixin fontBeauty {
    -webkit-font-smoothing: antialiased;    // Webkit系ブラウザ（Safari/Google Chrome/Android等）
    -moz-osx-font-smoothing: grayscale;     // FireFox（Mac版）
    -webkit-font-feature-settings: "palt" 1;
    font-feature-settings: "palt" 1;
    letter-spacing: .17em;
}

// font-family
@mixin fontFamilySerif() {
    font-family: 'ヒラギノ明朝 Pro W3', 'Hiragino Mincho Pro', '游明朝','Yu Mincho', '游明朝体', 'YuMincho','ＭＳ Ｐ明朝', 'MS PMincho', serif;
}

@mixin fontFamilySansSerif() {
    font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Segoe UI','Noto Sans Japanese','ヒラギノ角ゴ ProN W3', Meiryo, sans-serif;
}

@mixin fontFamily-gglFontsENG() {
    font-family: 'Noto Sans', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Segoe UI','Noto Sans Japanese','ヒラギノ角ゴ ProN W3', Meiryo, sans-serif;
}

@mixin fontFamily-gglFontsJP() {
    font-family: 'Noto Sans JP', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Segoe UI','Noto Sans Japanese','ヒラギノ角ゴ ProN W3', Meiryo, sans-serif;
}


// selection ----------------------------------
@mixin selection {
    ::-moz-selection { @content; }
    ::selection { @content; }
}


// scrollbar ----------------------------------
@mixin scrollbar() {
    // IE スクロールバー
    scrollbar-base-color: #FFF;         // 基本色
    scrollbar-track-color: #FFF;        // レール部分
    scrollbar-face-color: $color_2;     // ボタン部分
    scrollbar-shadow-color: #FFF;       // 内側の影
    scrollbar-darkshadow-color: #FFF;   // 外側の影
    scrollbar-highlight-color: #FFF;    // 内側のハイライト
    scrollbar-3dlight-color: #FFF;      // 外側のハイライト
    scrollbar-arrow-color: #ccc;        // 矢印の色
    filter:chroma(color=#FFF)!important; // 透明にしたい色の指定

    // windows スクロールバー
    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-track {
        border-radius: 10px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, .1);

    }
    &::-webkit-scrollbar-thumb {
        background-color: rgba(#adea0e, 0.6);
        border-radius: 10px;
        box-shadow:0 0 0 1px rgba(255, 255, 255, .3);
    }
}

@mixin scrollbarWorks() {
    // IE スクロールバー
    scrollbar-base-color: #4a4a52;       // 基本色
    scrollbar-track-color: #4a4a52;      // レール部分
    scrollbar-face-color: #3f3f46;       // ボタン部分
    scrollbar-shadow-color: #4a4a52;     // 内側の影
    scrollbar-darkshadow-color: #4a4a52; // 外側の影
    scrollbar-highlight-color: #4a4a52;  // 内側のハイライト
    scrollbar-3dlight-color: #4a4a52;    // 外側のハイライト
    scrollbar-arrow-color: #3f3f46;      // 矢印の色
    filter:chroma(color=#FFF)!important; // 透明にしたい色の指定

    // windows スクロールバー
    &::-webkit-scrollbar-thumb {
      background-color: rgba(#494952, 0.6);
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(#36363d, 0.8);
      box-shadow: none;
    }
}


// accessory
@mixin placeholderColor($color) {
    &:placeholder-shown {
        color: $color;
    }

    &::-webkit-input-placeholder {
        color:$color;
    }

    &:-moz-placeholder {
        color:$color;
        opacity: 1;
    }

    &::-moz-placeholder {
        color:$color;
        opacity: 1;
    }

    &:-ms-input-placeholder {
        color:$color;
    }
}

// box
@mixin tableCol($verticalAlign) {
    display: table;

    & > * {
        float: none;
        display: table-cell;
        vertical-align: $verticalAlign;
    }
}

@mixin EqualWidthColumn($num) {
    margin-left: -$num;
    margin-right: -$num;

    & > * {
        padding: 0 $num;
    }
}

@mixin col_2($num) {
    @include EqualWidthColumn($num);

    & > *:nth-child(2n+1) {
        clear: both;
    }

    & > *:nth-child(n+3) {
        padding-top: ($num);
    }
}

@mixin col_3($num) {
    @include EqualWidthColumn($num);

    & > *:nth-child(3n+1) {
        clear: both;
    }

    & > *:nth-child(n+4) {
        padding-top: ($num);
    }
}

@mixin col_4($num) {
    @include EqualWidthColumn($num);

    & > *:nth-child(4n+1) {
        clear: both;
    }

    & > *:nth-child(n+5) {
        padding-top: ($num);
    }
}

@mixin col_5($num) {
    @include EqualWidthColumn($num);

    & > *:nth-child(5n+1) {
        clear: both;
    }

    & > *:nth-child(n+6) {
        padding-top: ($num);
    }
}

@mixin col_6($num) {
    @include EqualWidthColumn($num);

    & > *:nth-child(6n+1) {
        clear: both;
    }

    & > *:nth-child(n+7) {
        padding-top: ($num);
    }
}

@mixin col_7($num) {
    @include EqualWidthColumn($num);

    & > *:nth-child(7n+1) {
        clear: both;
    }

    & > *:nth-child(n+8) {
        padding-top: ($num);
    }
}

@mixin col_8($num) {
    @include EqualWidthColumn($num);

    & > *:nth-child(8n+1) {
        clear: both;
    }

    & > *:nth-child(n+9) {
        padding-top: ($num);
    }
}

@mixin col_9($num) {
    @include EqualWidthColumn($num);

    & > *:nth-child(9n+1) {
        clear: both;
    }

    & > *:nth-child(n+10) {
        padding-top: ($num);
    }
}

// fixed layout
@mixin rectangle4to3() {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 75% 0 0;

    & > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background:  #b40d0d;
    }
}

@mixin rectangle16to9() {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 56.25% 0 0;

    & > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

// margin / padding
@mixin margin($p, $m) {
    @include sm {
        margin: $p;
    }

    @include mt {
        margin: $m;
    }
}

@mixin marginTop($p, $m) {
    @include sm {
        margin-top: $p;
    }

    @include mt {
        margin-top: $m;
    }
}

@mixin marginLeft($p, $m) {
    @include sm {
        margin-left: $p;
    }

    @include mt {
        margin-left: $m;
    }
}

@mixin marginBottom($p, $m) {
    @include sm {
        margin-bottom: $p;
    }

    @include mt {
        margin-bottom: $m;
    }
}

@mixin padding($p, $m) {
    @include sm {
        padding: $p;
    }

    @include mt {
        padding: $m;
    }
}

@mixin paddingSide($p, $m) {
    @include sm {
        padding: 0 $p;
    }

    @include mt {
        padding: 0 $m;
    }
}

@mixin paddingVertical($p, $m) {
    @include sm {
        padding: $p 0;
    }

    @include mt {
        padding: $m 0;
    }
}

@mixin paddingTop($p, $m) {
    @include sm {
        padding-top: $p;
    }

    @include mt {
        padding-top: $m;
    }
}

@mixin paddingLeft($p, $m) {
    @include sm {
        padding-left: $p;
    }

    @include mt {
        padding-left: $m;
    }
}

@mixin paddingRight($p, $m) {
    @include sm {
        padding-right: $p;
    }

    @include mt {
        padding-right: $m;
    }
}

@mixin paddingBottom($p, $m) {
    @include sm {
        padding-bottom: $p;
    }

    @include mt {
        padding-bottom: $m;
    }
}
