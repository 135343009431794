@charset "UTF-8";

/*--- area ---*/
.wrapper {
    width: 100%;
    height: 100%;
    position: relative;
}

.container {
    width: 100%;
    height: 100%;
    min-height: 100%;
    position: relative;
}

.scroll-area {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        display: none;
    }

    @include onecol {
        overflow: inherit;
    }
}

/*--- box ---*/
.fixed-bg {
    // width: 300%;
    width: 50%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    // right: 0;
    // bottom: 0;
    // background: url(../images/bg-cover.jpg) center center / contain;
    background: url(../images/bg-cover.jpg) center center / cover;
    z-index: -1;
    // animation: bgScroll 150s linear infinite;

    @include onecol {
        width: 100%;
        background: none;
        animation: none;
    }

    &:before {
        @include onecol {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            position: fixed;
            top: 0;
            left: 0;
            background-image: url(../images/bg-cover.jpg);
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            z-index: -1;
        }
    }

    @keyframes bgScroll {
        0% {
            transform: translate3d( 0, 0, 0 );
            opacity: 1;
        }
        99% {
            opacity: 1;
        }
        100% {
            transform: translate3d( -1282px, 0, 0 );
            opacity: .2;
        }
    }
}

.split {
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;

    @include onecol {
        display: block;
    }

    .split__screen {
        width: 50%;
        height: 100vh;
        position: relative;

        @include onecol {
            width: 100%;
        }

        &--left {
            .split__screen-box {
                margin-left: 150px;
                color: $base_color;

                @include onecol {
                    margin-left: 0;
                }

                &--top {
                    height: 50%;
                    position: relative;

                    @include mb {
                        height: 40%;
                    }

                    .split__screen-ttl {
                        position: absolute;
                        bottom: 10%;
                        left: -3.5%;
                        @include fontBeauty();
                        font-size: calc(100vw / 16);
                        font-weight: bold;
                        z-index: 10;
                        line-height: normal;

                        @include onecol {
                            left: 10%;
                            font-size: calc(100vw / 7);
                        }
                    }
                }

                &--btm {
                    height: 50%;
                    padding: 0 10%;

                    @include onecol {
                        padding: 0 10% 10%;
                    }

                    @include mb {
                        height: 60%;
                    }

                    .split__screen-txt {
                        height: 55%;
                        padding-bottom: 5%;
                    }

                    .split__screen-news {
                        height: 45%;
                        font-size: $sizeS;

                        .screen-news {
                            display: flex;
                            flex-wrap: wrap;

                            .screen-news__days {
                                width: 8em;
                                color: $key_color;

                                @include onecol {
                                    width: 100%;
                                }
                            }

                            .screen-news__ttl {
                                width: calc( 100% - 8em);
                                margin-bottom: .5em;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;

                                @include onecol {
                                    width: 100%;
                                    margin-bottom: 1em;
                                }
                            }
                        }
                    }
                }
            }
        }

        &--right {
            overflow: hidden;
            background: $base_color;

            @include onecol {
                height: auto;
            }
        }
    }
}

.border-frame {
    width: 100%;
    height: 100%;
    border-left: 1px solid $key_color;
    border-right: 1px solid $color_3;

    &:before,
    &:after {
        content:"";
        display:block;
        height:1px;
        width:100%;
        background:linear-gradient(to left, $color_3, $key_color);
    }
}

.loading {
    z-index: 11;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: none;
    width: 100%;
    height: 100%;
    background: rgba(#fff, 0.3);

    .loading_wrapper {
        display: table;
        width: 100%;
        height: 100%;

        .loading_body {
            display: table-cell;
            text-align: center;
            vertical-align: middle;

            .loader {
                color: $key_color;
                font-size: 50px;
                text-indent: -9999em;
                overflow: hidden;
                width: 1em;
                height: 1em;
                border-radius: 50%;
                margin: 72px auto;
                position: relative;
                transform: translateZ(0);
                animation: load6 1.7s infinite ease, round 1.7s infinite ease;
            }

            @keyframes load6 {
                0% {
                    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
                }
                5%,
                95% {
                    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
                }
                10%,
                59% {
                    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
                }
                20% {
                    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
                }
                38% {
                    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
                }
                100% {
                    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
                }
            }

            @keyframes round {
                0% {
                    transform: rotate(0deg);
                }

                100% {
                    transform: rotate(360deg);
                }
            }
        }
    }
}

.page-nav {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .page-nav__list {
        a {
            display: flex;
            align-items: center;

            img {
                max-width: 14px;
            }
        }

        &--prev {
            a {
                img {
                    margin-right: .75em;
                }
            }
        }

        &--next {
            a {
                margin-left: 2em;

                img {
                    margin-left: .75em;
                }
            }
        }
    }
}
