@charset "UTF-8";

/*--- title ---*/

.ttl-main {
    margin-left: 10%;
    position: relative;
    @include fontFamily-gglFontsENG();
    @include fontBeauty();
    font-weight: bold;
    line-height: normal;
    text-transform: uppercase;
    color: $ttl_color;
    font-size: calc(50vw / 12);

    @include onecol {
        margin-left: 9%;
        font-size: calc(100vw / 12);
    }

    &:first-letter{
        font-size: calc(50vw / 5);
        color: $key_color;

        @include onecol {
            font-size: calc(100vw / 5);
        }
    }

    &:before {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        position: absolute;
        top: 67%;
        transform: translateY(-70%);
        background: linear-gradient(to right, $color_3, $key_color);
    }
}

.ttl-sub {
    color: $ttl_color;
    text-align: center;

    .ttl-sub__eng {
        @include fontFamily-gglFontsENG();
        @include fontBeauty();
        font-size: calc(50vw / 24);

        @include onecol {
            font-size: calc(100vw / 24);
        }

        @include max414 {
            font-size: calc(100vw / 18);
        }
    }

    .ttl-sub__jp {
        font-size: $sizeS;
    }
}

.caplock {
    width: 100%;
    height: 0;
    padding-top: calc(90 / 200 * 100%);
    position: relative;

    .caplock__col {
        width: 60%;
        height: 0;
        padding-top: 42%;
        padding-top: calc(130 / 200 * 60%);
        background: $color_2;
        border-radius: 3px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 5%;
    }

    .caplock__img {
        width: 60%;
        height: 0;
        padding-top: 42%;
        padding-top: calc(130 / 200 * 60%);
        background: no-repeat center / cover;
        border-radius: 3px;
        position: absolute;
        top: 0;
        right: -20px;

        &--vision {
            background-image: url(../images/pict-concept.jpg);
        }

        &--prof {
            background-image: url(../images/pict-prof.jpg);
        }
    }

    .caplock__ttl {
        width: 60%;
        height: 0;
        padding-top: 42%;
        padding-top: calc(130 / 200 * 60%);
        background: rgba(#ffffff, 1);
        border-radius: 3px;
        text-align: center;
        position: absolute;
        bottom: 0;
        left: -20px;

        &--inner {
            width: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
        }
    }
}
