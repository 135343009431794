@charset "UTF-8";

/*-----------------------------------------------
news
-----------------------------------------------*/

main.news {

    &--detail {
        .split__screen--left {
            @include onecol {
                display: none;
            }
        }

        .split__screen--right {
            @include onecol {
                height: auto;
                min-height: 100vh;
            }
        }

        .news-detail {
            padding: 5% 0;

            @include mt {
                padding: 5% 0;
            }

            .news-detail__ttl {
                margin-bottom: 20px;

                @include mt {
                    margin-bottom: 5%;
                }
            }

            .news-detail__post {
                argin-bottom: 5%;
                padding: 0 10%;

                .news-detail__post-time {
                    color: $key_color;
                }

                .news-detail__post-ttl {
                    margin-bottom: 1.5em;
                    font-size: $sizeM;
                }

                .news-detail__post-cont {
                    a {
                        color: $key_color;

                        &:hover {
                            opacity: .6;
                        }
                    }
                }
            }

            .news-detail__page-nav {
                margin-top: 15%;
                padding: 0 10%;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                @include onecol {
                    justify-content: space-between;
                }

                .news-detail__page-nav__home {
                    display: none;

                    @include onecol {
                        display: block;
                    }

                    a {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            max-width: 16px;
                            margin-right: .75em;
                        }
                    }
                }
            }
        }
    }
}
