@charset "UTF-8";

/*--- button ---*/
.btn {
    width: 100%;
    max-width: 180px;

    @include mb {
        max-width: 100%;
    }

    &--standard {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $font_color;
        border-radius: 20px;
        color: $base_color;
        cursor: pointer;

        @include mb {
            max-width: 100%;
        }

        &:hover {
            opacity: .8;
        }
    }

    a {
        display: block;
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $font_color;
        border-radius: 20px;
        color: $base_color;


        @include mb {
            max-width: 100%;
        }

        &:hover {
            opacity: .8;
        }
    }
}
