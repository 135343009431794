@charset "UTF-8";

/*--- modal ---*/

.privacy {
    font-size: 1.2rem;

    .privacy-def {
        .privacy-def__ttl {
            color: #8aba00;
            font-size: 1.4rem;
        }

        .privacy-def__data {
            margin-bottom: 1em;
        }
    }

    .privacy-note {
        text-align: right;
    }
}
