@charset "UTF-8";

/*--- pluginName ---*/

#js-slick_works {
    position: relative;
    padding-top: 27px;

    .slick-arrow {
        position: absolute;
        top: 0;
        right: 1px;
        width: 40px;
        height: 27px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover {
            transition:  .3s ease;
            opacity: .6;
        }

        &--prev {
            right: 40px;
        }

        img {
            max-width: 16px;
        }
    }

    .slick-dots {
        position: absolute;
        top: 2px;
        left: 0;
        height: 26px;
        display: flex;
        max-width: 70%;

        li {
            margin: 0;
            padding: 0;
        }
    }
}

.js-drawer {
    button {
        width: 2.4rem;
        padding-right: 2.5%;

        .drawer-hamburger-icon {
            height: 1px;
            color: $ttl_color;

            &::before,
            &::after {
                width: 80%;
                height: 1px;
                color: $ttl_color;
            }
        }
    }

    .drawer-nav {
        height: 100%;
        max-height: 100%;
        @include fontFamily-gglFontsENG();
        font-size: 1.6rem;
        background-color: rgba(188, 255, 0, 0.8);
        background: linear-gradient(to bottom, rgba(180, 232, 39, 0.8) 0%,rgba(232, 39, 174, 0.8) 100%);
        color: $base_color;
    }
}
