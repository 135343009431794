@charset "UTF-8";

/*--- input ---*/

// common -------------
input, select, textarea, button, option {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    border-radius: 0;
    outline: none;
    -webkit-appearance: none;
    appearance: none;
    font-size: 1.4rem;
    line-height: 1.8;
    letter-spacing: 0.08em;

    @include mb {
        font-size: 1.6rem;
    }
}


// base ----------------
.label {
    display: block;
    width: 100%;
    margin-bottom: .25em;
}

.req {
    margin-left: .25em;
    font-size: $sizeS;
    color: $color_3;
}

.input {
    width: 100%;
    padding: .5em 1em;
    background: $base_color;
    border-radius: 3px;

    @include mb {
        padding: .5em;
    }

    &--select {
        cursor: pointer;
        background: #fff url(../images/icon-arrow_down.png) no-repeat center right 1em;
        background-size: 1.75em;
    }

    &--textarea {
        height: 13.5em;

        @include mb {
            height: 10em;
        }
    }
}

.form-block {
    display: flex;
    flex-wrap: wrap;

    & + .form-block {
        margin-top: 20px;
    }
}

.form-comment {
    margin-top: .5em;
    padding-left: 1.5em;
    padding-left: calc(10px + 1em);
    font-size: $sizeS;

    a {
        color: $color_5;
    }
}


// contact form7 --------
form.wpcf7-form {

    // 自動コメント欄
    .wpcf7-response-output {
        padding: 2em 0;
        margin: 0;
        border: 0;
        position: relative;

        &.wpcf7-mail-sent-ok,
        &.wpcf7-validation-errors,
        &.wpcf7-acceptance-missing {
            border: 0;
            padding-left: 2em;

            &:before {
                display: block;
                content: '';
                width: 1.5em;
                height: 1.5em;
                background-size: contain;
                background-position: center center;
                background-repeat: no-repeat;
                position: absolute;
                top: 2.2em;
                left: 0;
            }
        }

        // 完了時
        &.wpcf7-mail-sent-ok {
            color: $color_5;

            &:before {
                background-image: url(../images/icon_send.png);
            }
        }

        // エラー時
        &.wpcf7-validation-errors,
        &.wpcf7-acceptance-missing {
            color: $color_4;

            &:before {
                background-image: url(../images/icon_error.png);
            }
        }
    }

    .wpcf7-form-control-wrap {
        width: 100%;

        // 姓名 横並び
        &.your-name-sei {
            width: 47.5%;
            width: calc(50% - 2.5%);
            margin-right: 2.5%;
        }
        &.your-name-mei {
            width: 47.5%;
            width: calc(50% - 2.5%);
            margin-left: 2.5%;
        }

        // checkbox
        &.your-check {
            .wpcf7-checkbox {
                span.wpcf7-list-item {
                    margin: 0;

                    input[type="checkbox"] {
                        display: none;
                    }

                    span.wpcf7-list-item-label {
                        display: inline-block;
                        position: relative;
                        padding-left: 1.5em;
                        padding-left: calc(10px + 1em);
                        vertical-align: middle;
                        cursor: pointer;

                        &:hover {
                            opacity: .8;
                        }

                        &:after {
                            content: '';
                            display: block;
                            position: absolute;
                            top: 50%;
                            left: 8px;
                            width: 10px;
                            height: 18px;
                            margin-top: -14px;
                            border-right: 3px solid $key_color;
                            border-bottom: 3px solid $key_color;
                            transform: rotate(45deg);
                            transition: opacity .2s linear;
                            opacity: 0;
                        }

                        &:before {
                            content: '';
                            display: block;
                            position: absolute;
                            top: 50%;
                            left: 0;
                            transform: translateY(-50%);
                            width: 1em;
                            height: 1em;
                            border: 1px solid $font_color;
                            border-radius: 2px;
                        }
                    }

                    input[type=checkbox]:checked {
                        & + span.wpcf7-list-item-label {

                            &:after {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }

        // 各項目不備エラー
        .wpcf7-not-valid-tip {
            font-size: $sizeS;
            color: $color_4;
        }
    }

    .wpcf7-submit {
        margin-top: 20px;
        margin-left: auto;
        display: block;
    }
}
